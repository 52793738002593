import ButtonIconBorder from "./Common/ButtonIconBorder";
import ContactUs from "./Common/ContactUs";
import Footer from "./Footer/Footer";
import Navigation from "./Navigation/Navigation";

const SingleCaseStudy = (props) => {
  return (
    <div>
      <Navigation />
      <div className='case-study-section'>
        <div className='container h-100'>
          <div className='case-study-page'>
            <div className='case-study-image'>
              <img className='image' src={props.image} alt='case-study-img' />
            </div>
            <div className='case-study-details'>
              <h3 className='title'>{props.title}</h3>
              {/* industry tags */}
              <div className='case-study-tags'>
                <div className='industry-tag'>{props.industry}</div>
                <div className='industry-tag'>{props.technology}</div>
                <div className='industry-tag'>{props.dataType}</div>
              </div>
              <h4 className='subtitle'>Overview of Case Study</h4>
              <div className='description'>{props.description}</div>
              <a href={props.link} target="_blank" rel="noreferrer">
                <div>
                  <ButtonIconBorder
                  name='Read More'
                  svgPathAttributes={{
                    fill: "currentColor",
                    d: "M4 11.25a.75.75 0 0 0 0 1.5h9.25V18a.75.75 0 0 0 1.28.53l6-6a.75.75 0 0 0 0-1.06l-6-6a.75.75 0 0 0-1.28.53v5.25H4Z",
                  }}
                  styleClasses='button'
                />
                </div>
              </a>
            </div>
          </div>
          <div className='case-study-button-text'>
            <h4 className='title'>
              We're excited to share our knowledge and empower your journey
              towards excellence. Browse through our detailed case study.
            </h4>
          </div>
        </div>
      </div>
      <ContactUs />
      <Footer />
    </div>
  );
};

export default SingleCaseStudy;
